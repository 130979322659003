import { Component, OnInit } from '@angular/core';
import { faGear, faWrench } from '@fortawesome/free-solid-svg-icons'
import { PartsService } from '../Services/parts.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.css'
})

export class SearchPageComponent implements OnInit {
  isMoved = false;
  backgroundPlate = true;
  logoUpSide = false;
  loading = false;
  CenterLoader = false;
  searchQuery = '';
  icon1 = faGear;
  icon2 = faWrench;
  TwoButtons = true;
  isBackgroundChanged: boolean = false;
  selectedTabIndex: number = 0;
  headers: string[] = [];
  parts: any[] = [];
  acesPartObject: { [key: string]: string } = {};
  partNumber: string = '';
  isCardOpen: boolean = false;
  isOpen: boolean = false
  openedCardIndex: number | null = null;
  newPartData: any[] = [];
  priceData: any[] = [];
  productAttributes: string[] = [];
  descriptions: string[] = [];
  packages: any = {};
  dimensions: any = {};
  weights: any = {};
  acesPartArray: any;
  noData: boolean = false



  constructor(private partsService: PartsService) { }


  selectTab(index: number) {
    this.selectedTabIndex = index;


  }

  GetAces(){
    this.selectedTabIndex = 0;

    if (this.searchQuery.trim()) {
      let request = {
        "partNumber": this.searchQuery
      }
      this.CenterLoader = true;
      this.partsService.data = [];
      this.partsService.getAces(request).subscribe(response => {
        this.CenterLoader = false;
        if (response && response.length == 0) {
          console.log("line 96")
          this.noData = true;
          this.partNumber = this.searchQuery;
        } else {
          console.log(response, "line 99")
          this.noData = false;

          console.log(response, 'AcesRes')
          this.partsService.data.push(response);
          const data = this.partsService.getParts();
          this.headers = data.headers;
          this.parts = data.parts;
          this.acesPartObject = this.partsService.getAcesPartObject();
          if (this.acesPartObject['Qual']) {
            delete this.acesPartObject['Qual'];
          }
          console.log("98");
          this.partNumber = this.partsService.getPartNumber();
          this.acesPartArray = response.acesPartArray || [];
        }
      })
    }
  }

  GetPies() {
    let request = {
      "partNumber": this.searchQuery
    }
    this.CenterLoader = true;
    this.partsService.newPartData = [];
    this.newPartData = [];
    this.partsService.getPies(request).subscribe(response => {
      this.CenterLoader = false;
      if (response && response.length == 0) {
        console.log("line 96")
        this.noData = true;
        this.partNumber = this.searchQuery;
      } else{
      this.noData = false;
      console.log(response, 'PiesRes')
      this.partsService.newPartData = response;
      this.newPartData = response;
      // this.priceData = this.partsService.getPricesData();
      console.log(this.newPartData, '65')
      // this.productAttributes = this.partsService.getProductAttributes();
      // this.descriptions = this.partsService.getDescriptions();
      // this.packages = this.partsService.getPackages();
      // this.dimensions = this.packages.Dimensions;
      // this.weights = this.packages.Weights;
    }
    })
  }

  moveToTopLeft() {

    this.selectedTabIndex = 0;

    if (this.searchQuery.trim()) {
      this.CenterLoader = true;
      console.log(this.searchQuery, '54');
      this.isMoved = true;
      this.backgroundPlate = false;
      this.logoUpSide = true;
      this.TwoButtons = false;
      this.isBackgroundChanged = true;



      let request = {
        "partNumber": this.searchQuery
      }

      this.partsService.data = [];
      this.partsService.getAces(request).subscribe(response => {
        this.CenterLoader = false;
        if (response && response.length == 0) {
          console.log("line 96")
          this.noData = true;
          this.partNumber = this.searchQuery;
        } else {
          console.log(response, "line 99")
          this.noData = false;

          console.log(response, 'AcesRes')
          this.partsService.data.push(response);
          const data = this.partsService.getParts();
          this.headers = data.headers;
          this.parts = data.parts;
          this.acesPartObject = this.partsService.getAcesPartObject();
          if (this.acesPartObject['Qual']) {
            delete this.acesPartObject['Qual'];
          }
          console.log("98");
          this.partNumber = this.partsService.getPartNumber();
          this.acesPartArray = response.acesPartArray || [];
        }
      })


    }
  }


  refreshPage() {
    location.reload();
  }

  ngOnInit(): void {

    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);


    this.moveToTopLeft();


  }

  objectKeys(obj: { [key: string]: string }): string[] {
    return Object.keys(obj);
  }

  toggleCard(index: number) {
    this.newPartData[index].isOpen = !this.newPartData[index].isOpen;
  }

  trackByFn(index: number, item: any) {
    return index; // or item.id if you have an identifier
  }

  formatDimensions(dimensions: any): string {
    let dimensionsString = `UOM: ${dimensions.UOM}`;
  
    for (const key in dimensions) {
      if (key !== 'UOM') {
        dimensionsString += `, ${key}: ${dimensions[key]}`;
      }
    }
  
    return dimensionsString;
  }

  formatWeights(weights: any): string {
    let weightsString = `UOM: ${weights.UOM}`;
  
    for (const key in weights) {
      if (key !== 'UOM') {
        weightsString += `, ${key}: ${weights[key]}`;
      }
    }
  
    return weightsString;
  }
  
  formatPartInterchangeInfo(PartInterchangeInfo: any): string {
    return ` MaintenanceType: ${PartInterchangeInfo.MaintenanceType}, LanguageCode: ${PartInterchangeInfo.LanguageCode}, TypeCode: ${PartInterchangeInfo.TypeCode}, BrandAAIAID: ${PartInterchangeInfo.BrandAAIAID}, PartNumber: ${PartInterchangeInfo.PartNumber}`;
  }

}

