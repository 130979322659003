<div class="loading-bar" *ngIf="loading">
  <div class="green-line"></div>
  <!-- Green animation line -->
  <img src="assets\images\Car.png" alt="Car Icon" />
</div>

<!-- <div class="animation-container" *ngIf="CenterLoader">
  <svg
    class="pl"
    viewBox="0 0 160 160"
    width="160px"
    height="160px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stop-color="#000"></stop>
        <stop offset="100%" stop-color="#fff"></stop>
      </linearGradient>
      <mask id="mask1">
        <rect x="0" y="0" width="160" height="160" fill="url(#grad)"></rect>
      </mask>
      <mask id="mask2">
        <rect x="28" y="28" width="104" height="104" fill="url(#grad)"></rect>
      </mask>
    </defs>

    <g>
      <g class="pl__ring-rotate">
        <circle
          class="pl__ring-stroke"
          cx="80"
          cy="80"
          r="72"
          fill="none"
          stroke="hsl(223,90%,55%)"
          stroke-width="16"
          stroke-dasharray="452.39 452.39"
          stroke-dashoffset="452"
          stroke-linecap="round"
          transform="rotate(-45,80,80)"
        ></circle>
      </g>
    </g>
    <g mask="url(#mask1)">
      <g class="pl__ring-rotate">
        <circle
          class="pl__ring-stroke"
          cx="80"
          cy="80"
          r="72"
          fill="none"
          stroke="hsl(193,90%,55%)"
          stroke-width="16"
          stroke-dasharray="452.39 452.39"
          stroke-dashoffset="452"
          stroke-linecap="round"
          transform="rotate(-45,80,80)"
        ></circle>
      </g>
    </g>

    <g>
      <g
        stroke-width="4"
        stroke-dasharray="12 12"
        stroke-dashoffset="12"
        stroke-linecap="round"
        transform="translate(80,80)"
      >
        <polyline
          class="pl__tick"
          stroke="hsl(223,10%,90%)"
          points="0,2 0,14"
          transform="rotate(-135,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,10%,90%)"
          points="0,2 0,14"
          transform="rotate(-90,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,10%,90%)"
          points="0,2 0,14"
          transform="rotate(-45,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,10%,90%)"
          points="0,2 0,14"
          transform="rotate(0,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,10%,90%)"
          points="0,2 0,14"
          transform="rotate(45,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,10%,90%)"
          points="0,2 0,14"
          transform="rotate(90,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,10%,90%)"
          points="0,2 0,14"
          transform="rotate(135,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,10%,90%)"
          points="0,2 0,14"
          transform="rotate(180,0,0) translate(0,40)"
        ></polyline>
      </g>
    </g>
    <g mask="url(#mask1)">
      <g
        stroke-width="4"
        stroke-dasharray="12 12"
        stroke-dashoffset="12"
        stroke-linecap="round"
        transform="translate(80,80)"
      >
        <polyline
          class="pl__tick"
          stroke="hsl(223,90%,80%)"
          points="0,2 0,14"
          transform="rotate(-135,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,90%,80%)"
          points="0,2 0,14"
          transform="rotate(-90,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,90%,80%)"
          points="0,2 0,14"
          transform="rotate(-45,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,90%,80%)"
          points="0,2 0,14"
          transform="rotate(0,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,90%,80%)"
          points="0,2 0,14"
          transform="rotate(45,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,90%,80%)"
          points="0,2 0,14"
          transform="rotate(90,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,90%,80%)"
          points="0,2 0,14"
          transform="rotate(135,0,0) translate(0,40)"
        ></polyline>
        <polyline
          class="pl__tick"
          stroke="hsl(223,90%,80%)"
          points="0,2 0,14"
          transform="rotate(180,0,0) translate(0,40)"
        ></polyline>
      </g>
    </g>

    <g>
      <g transform="translate(64,28)">
        <g class="pl__arrows" transform="rotate(45,16,52)">
          <path
            fill="hsl(3,90%,55%)"
            d="M17.998,1.506l13.892,43.594c.455,1.426-.56,2.899-1.998,2.899H2.108c-1.437,0-2.452-1.473-1.998-2.899L14.002,1.506c.64-2.008,3.356-2.008,3.996,0Z"
          ></path>
          <path
            fill="hsl(223,10%,90%)"
            d="M14.009,102.499L.109,58.889c-.453-1.421,.559-2.889,1.991-2.889H29.899c1.433,0,2.444,1.468,1.991,2.889l-13.899,43.61c-.638,2.001-3.345,2.001-3.983,0Z"
          ></path>
        </g>
      </g>
    </g>
    <g mask="url(#mask2)">
      <g transform="translate(64,28)">
        <g class="pl__arrows" transform="rotate(45,16,52)">
          <path
            fill="hsl(333,90%,55%)"
            d="M17.998,1.506l13.892,43.594c.455,1.426-.56,2.899-1.998,2.899H2.108c-1.437,0-2.452-1.473-1.998-2.899L14.002,1.506c.64-2.008,3.356-2.008,3.996,0Z"
          ></path>
          <path
            fill="hsl(223,90%,80%)"
            d="M14.009,102.499L.109,58.889c-.453-1.421,.559-2.889,1.991-2.889H29.899c1.433,0,2.444,1.468,1.991,2.889l-13.899,43.61c-.638,2.001-3.345,2.001-3.983,0Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</div> -->

<div class="animation-container" *ngIf="CenterLoader">
<div class="loader">
  <svg
    xml:space="preserve"
    viewBox="0 0 254.532 254.532"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    version="1.1"
    class="wheel"
  >
    <g>
      <path
        d="M127.267,0C57.092,0,0,57.091,0,127.266s57.092,127.266,127.267,127.266c70.174,0,127.266-57.091,127.266-127.266
				S197.44,0,127.267,0z M127.267,217.656c-49.922,0-90.391-40.468-90.391-90.39s40.469-90.39,90.391-90.39
				c49.92,0,90.39,40.468,90.39,90.39S177.186,217.656,127.267,217.656z"
        id="tire"
      ></path>
      <path
        d="M127.267,48.578c-43.39,0-78.689,35.299-78.689,78.688c0,43.389,35.3,78.688,78.689,78.688
				c43.389,0,78.688-35.299,78.688-78.688C205.955,83.877,170.655,48.578,127.267,48.578z M195.878,122.249h-38.18
				c-0.78-4.825-2.686-9.275-5.435-13.079l26.954-26.954C188.679,93.112,194.771,106.996,195.878,122.249z M132.204,58.648
				c15.244,1.087,29.123,7.156,40.025,16.591l-26.948,26.949c-3.804-2.748-8.253-4.653-13.077-5.433V58.648z M122.329,58.648v38.106
				c-4.824,0.78-9.274,2.685-13.078,5.434L82.302,75.24C93.204,65.805,107.085,59.735,122.329,58.648z M75.313,82.217l26.955,26.954
				c-2.749,3.803-4.654,8.253-5.434,13.077h-38.18C59.761,106.996,65.853,93.113,75.313,82.217z M58.643,132.123h38.192
				c0.779,4.824,2.685,9.274,5.434,13.078l-27.029,27.029C65.788,161.308,59.714,147.398,58.643,132.123z M122.329,195.884
				c-15.285-1.09-29.197-7.188-40.113-16.666l27.035-27.035c3.804,2.749,8.254,4.654,13.078,5.434V195.884z M122.329,147.459v0.072
				c-2.131-0.518-4.131-1.36-5.953-2.474l0.047-0.047c-2.85-1.738-5.244-4.132-6.982-6.983l-0.046,0.046
				c-1.114-1.822-1.956-3.821-2.474-5.952h0.071c-0.385-1.585-0.611-3.233-0.611-4.937c0-1.704,0.227-3.352,0.611-4.937h-0.071
				c0.518-2.13,1.359-4.129,2.474-5.951l0.046,0.046c1.738-2.85,4.133-5.245,6.982-6.982l-0.047-0.047
				c1.822-1.114,3.822-1.957,5.953-2.474v0.072c1.586-0.385,3.233-0.612,4.938-0.612s3.352,0.227,4.938,0.612v-0.072
				c2.131,0.518,4.13,1.359,5.951,2.473l-0.047,0.047c2.851,1.737,5.245,4.132,6.983,6.982l0.046-0.046
				c1.115,1.822,1.957,3.822,2.475,5.953h-0.071c0.385,1.585,0.611,3.233,0.611,4.937c0,1.704-0.227,3.352-0.611,4.937h0.071
				c-0.518,2.131-1.359,4.131-2.475,5.953l-0.046-0.046c-1.738,2.85-4.133,5.244-6.983,6.982l0.047,0.046
				c-1.821,1.114-3.82,1.956-5.951,2.474v-0.072c-1.586,0.385-3.233,0.612-4.938,0.612S123.915,147.845,122.329,147.459z
				M132.204,195.884v-38.267c4.824-0.78,9.273-2.685,13.077-5.433l27.034,27.034C161.4,188.696,147.488,194.794,132.204,195.884z
				M179.292,172.23l-27.028-27.028c2.749-3.804,4.654-8.254,5.435-13.079h38.191C194.818,147.398,188.745,161.308,179.292,172.23z"
        id="rim"
      ></path>
    </g>
  </svg>
  <div class="road"></div>
</div>
</div>
<div class="background"></div>

<!-- <div *ngIf="backgroundPlate">
  <div class="PlatePlacement">
    <img class="plate" src="assets\images\Number plate.png" alt="" />
  </div>

  <div>
    <img class="Plogo" src="assets\images\p.png" alt="" />
    <img class="Alogo" src="assets\images\A.png" alt="" />
  </div>
</div> -->

<div class="search-container" [ngClass]="{ 'move-to-top-left': isMoved }">
  <div>
    <img
      *ngIf="logoUpSide"
      class="logoImg"
      src="assets\images\LogoWhite.png"
      alt=""
      (click)="refreshPage()"
    />
  </div>

  <div class="input-wrapper">
    <input
      *ngIf="!isMoved"
      [ngClass]="{ 'move-to-top-left': isMoved }"
      type="text"
      placeholder="Enter the part number..."
      class="search-input"
      [(ngModel)]="searchQuery"
      (keydown.enter)="moveToTopLeft()"
    />
    <input
      *ngIf="isMoved"
      type="text"
      placeholder="Enter the part number..."
      class="search-input2"
      [(ngModel)]="searchQuery"
      (keydown.enter)="moveToTopLeft()"
    />
    <button
      *ngIf="!isMoved"
      class="search-button"
      (click)="moveToTopLeft()"
      [disabled]="!searchQuery.trim()"
    >
      <img
        src="https://www.transparentpng.com/thumb/gear/setting-mark-grey-gear-png-icon--LfHXuY.png"
        alt="Gear"
        class="gear"
      />
      <img
        src="assets\images\Spanner2.png"
        alt="Spanner"
        [ngClass]="{ 'move-spanner': isMoved }"
        class="spanner"
      />
    </button>

    <button
      *ngIf="isMoved"
      class="search-button2"
      (click)="moveToTopLeft()"
      [disabled]="!searchQuery.trim()"
    >
      <img
        src="https://www.transparentpng.com/thumb/gear/setting-mark-grey-gear-png-icon--LfHXuY.png"
        alt="Gear"
        class="gear"
      />
      <img
        src="assets\images\Spanner2.png"
        alt="Spanner"
        [ngClass]="{ 'move-spanner': isMoved }"
        class="spanner"
      />
    </button>

    <div *ngIf="TwoButtons" style="margin-left: 14%" class="container">
      <div class="row mt-3">
        <div class="col">
          <!-- <button class="btn-shine">
            <span>Pies</span>
        </button>
        </div>
        <div class="col">
        <button class="btn-shine">
            <span>Aces</span>
        </button> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="background"
  [ngClass]="{ 'new-background': isBackgroundChanged }"
></div>

<div *ngIf="isMoved" class="tab-header-bottom">
  <div class="form-group">
    <label for="input1" style="margin-top: -7%;">
      <fa-icon [icon]="icon1" class="icon"></fa-icon>Part Number :
    </label>
    <h2 style="  color: white;
    border: 2px solid rgb(16, 167, 255);
    padding: 5px;
    border-radius:10px ; background-color: black;">{{partNumber}}</h2>
    <!-- <input
      type="text"
      class="form-control2"
      id="input1"
      placeholder="Input 1"
      [(ngModel)]="partNumber"
      readonly
    /> -->
  </div>
</div>

<div class="tab-container cards-container" *ngIf="isMoved">
  <div class="tab-header">
    <div
      class="tab"
      [class.active]="selectedTabIndex === 0"
      (click)="selectTab(0) ; GetAces()"
    >
      Aces
    </div>
    <div
      class="tab"
      [class.active]="selectedTabIndex === 1"
      (click)="selectTab(1); GetPies()"
    >
      Pies
    </div>
  </div>

  <div class="tab-content">
    <div *ngIf="selectedTabIndex === 0 && parts && parts.length > 0">
      <div *ngIf="!noData">
        <div class="card mt-3" *ngIf="headers.length > 0">
          <div class="cardHeaderOther">
            <div class="row">
              <div class="col-6">
                <!-- <div class="form-group">
              <label for="input1"> <fa-icon [icon]="icon1" class="icon"></fa-icon>Part Number :</label>
              <input type="text" class="form-control" id="input1" placeholder="Input 1">
            </div> -->
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-container">
              <table class="table table-striped table-hover table-dark">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th *ngFor="let header of headers">{{ header }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let part of parts; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td *ngFor="let header of headers">
                      <span
                        *ngIf="header === 'Note'"
                        matTooltip="{{ part[header] }}"
                        matTooltipClass="custom-tooltip"
                      >
                        {{ part[header] | slice : 0 : 35
                        }}{{ part[header].length > 35 ? "..." : "" }}
                      </span>
                      <span *ngIf="header !== 'Note'">{{ part[header] }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="headers.length == 0">
            <div class="card">
              No data Found
            </div>
        </div> -->
        <div class="card2 mt-3">
          <div class="row">
            <div
              *ngFor="let key of objectKeys(acesPartObject); let i = index"
              class="col-6"
            >
              <div class="form-group">
                <label [for]="'input' + i">
                  <fa-icon [icon]="icon1" class="icon"></fa-icon>{{ key }}:
                </label>
                <div class="input-container">
                  <!-- <ng-container *ngIf="acesPartObject[key]?.length && acesPartObject[key]?.length! > 50"> -->
                    <!-- <textarea
                      class="form-control"
                      style="height: 200px"
                      [value]="acesPartObject[key]"
                      readonly
                    ></textarea> -->
                  <!-- </ng-container> -->
                  <!-- <ng-container *ngIf="acesPartObject[key]?.length && acesPartObject[key]?.length! <= 50"> -->
                    <input
                      type="text"
                      class="form-control"
                      [id]="'input' + i"
                      [placeholder]="key"
                      [(ngModel)]="acesPartObject[key]"
                      readonly
                    />
                  <!-- </ng-container> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="selectedTabIndex === 1">
      <div class="card mt-3" *ngIf="!noData">
        <div class="cardHeaderOther">
          <div class="row">
            <div class="col-6">
              <!-- <div class="form-group">
              <label for="input1"> <fa-icon [icon]="icon1" class="icon"></fa-icon>Part Number :</label>
              <input type="text" class="form-control" id="input1" placeholder="Input 1">
            </div> -->
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-container">
            <!-- <div class="container mt-4"> -->
            <table class="table table-striped table-hover table-dark">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Maintenance Type</th>
                  <th>Item Level GTIN</th>
                  <th>Part Number</th>
                  <th>Brand AAIAID</th>
                  <th>Quantity Per Application</th>
                  <th>Part Terminology ID</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let part of newPartData;
                    let i = index;
                    trackBy: trackByFn
                  "
                >
                  <tr (click)="toggleCard(i)">
                    <td>{{ i + 1 }}</td>
                    <td>
                      {{ part.MaintenanceType ? part.MaintenanceType : "NA" }}
                    </td>
                    <td>
                      {{ part.ItemLevelGTIN ? part.ItemLevelGTIN : "NA" }}
                    </td>
                    <td>{{ part.PartNumber ? part.PartNumber : "NA" }}</td>
                    <td>{{ part.BrandAAIAID ? part.BrandAAIAID : "NA" }}</td>
                    <td>
                      {{
                        part.QuantityPerApplication
                          ? part.QuantityPerApplication
                          : "NA"
                      }}
                    </td>
                    <td>
                      {{
                        part.PartTerminologyID ? part.PartTerminologyID : "NA"
                      }}
                    </td>
                    <td>
                      <span
                        class="arrow-icon"
                        (click)="toggleCard(i); $event.stopPropagation()"
                        [ngClass]="{ open: part.isOpen }"
                        >&#9660;</span
                      >
                    </td>
                  </tr>
                  <tr *ngIf="part.isOpen" class="card-row">
                    <td colspan="12">
                      <div class="card3">
                        <div
                          *ngIf="
                            part.Prices &&
                            part.Prices.Pricing &&
                            part.Prices.Pricing.length > 0
                          "
                        >
                          <h5>
                            <fa-icon [icon]="icon1" class="icon2"></fa-icon>
                            PRICE:
                          </h5>
                          <table
                            class="table table-striped table-hover table-dark tableInside"
                          >
                            <thead class="SmallTableHeading">
                              <tr>
                                <th>S.NO</th>
                                <th>Maintenance Type</th>
                                <th>Price Type</th>
                                <th>Price Sheet Number</th>
                                <th>Effective Date</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <!-- {{
                              part.Prices
                                ? "pricing exists"
                                : "no pricing exists"
                            }}
                            {{
                              part.Prices.Pricing.length
                            }} -->
                            <tbody>
                              <tr
                                *ngFor="
                                  let price of part.Prices.Pricing;
                                  let j = index
                                "
                              >
                                <td>
                                  {{ j + 1 }}
                                </td>
                                <td>
                                  {{ price.MaintenanceType }}
                                </td>
                                <td>
                                  {{ price.PriceType }}
                                </td>
                                <td>
                                  {{ price.PriceSheetNumber }}
                                </td>
                                <td>
                                  {{ price.EffectiveDate }}
                                </td>
                                <td>
                                  {{ price.Price }}
                                </td>
                              </tr>
                            </tbody>
                            <!-- <tbody>
                            <tr *ngFor="let part of priceData; let j = index">
                              <td *ngIf="i == part.indexValue">
                                {{ j + 1 }}
                              </td>
                              <td *ngIf="i == part.indexValue">
                                {{ part.MaintenanceType }}
                              </td>
                              <td *ngIf="i == part.indexValue">
                                {{ part.PriceType }}
                              </td>
                              <td *ngIf="i == part.indexValue">
                                {{ part.PriceSheetNumber }}
                              </td>
                              <td *ngIf="i == part.indexValue">
                                {{ part.EffectiveDate }}
                              </td>
                              <td *ngIf="i == part.indexValue">
                                {{ part.Price }}
                              </td>
                            </tr>
                          </tbody> -->
                          </table>
                        </div>
                        <div class="row">
                          <div
                            class="col-6 top"
                            *ngIf="
                              part.ProductAttributes &&
                              part.ProductAttributes.ProductAttribute &&
                              part.ProductAttributes.ProductAttribute.length > 0
                            "
                          >
                            <div class="mb-3">
                              <h5>
                                <fa-icon [icon]="icon1" class="icon2"></fa-icon>
                                Product Attributes
                              </h5>
                              <textarea
                                class="form-control"
                                style="height: 200px"
                                [value]="
                                  part.ProductAttributes.ProductAttribute.join(
                                    ', '
                                  )
                                "
                                readonly
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-6 top">
                            <div class="mb-3">
                              <h5>
                                <fa-icon [icon]="icon1" class="icon2"></fa-icon>
                                Description
                              </h5>
                              <textarea
                                class="form-control"
                                style="height: 200px"
                                [value]="
                                  part.Descriptions.Description.join(', ')
                                "
                                readonly
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div class="card2">
                          <div class="row">
                            <div class="cardHeader">
                              <h5>
                                <fa-icon [icon]="icon1" class="icon"></fa-icon
                                >Packages:
                              </h5>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                <label for="input1"
                                  ><fa-icon
                                    [icon]="icon2"
                                    class="icon"
                                  ></fa-icon
                                  >Maintenance Type:</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="input1"
                                  [value]="
                                    part.Packages.Package.MaintenanceType
                                      ? part.Packages.Package.MaintenanceType
                                      : 'NA'
                                  "
                                  readonly
                                />
                              </div>
                              <div class="form-group">
                                <label for="input2"
                                  ><fa-icon
                                    [icon]="icon2"
                                    class="icon"
                                  ></fa-icon
                                  >Package Level GTIN:</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="input2"
                                  [value]="
                                    part.Packages.Package.PackageLevelGTIN
                                      ? part.Packages.Package.PackageLevelGTIN
                                      : 'NA'
                                  "
                                  readonly
                                />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                <label for="input3"
                                  ><fa-icon
                                    [icon]="icon2"
                                    class="icon"
                                  ></fa-icon
                                  >Package UOM:</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="input3"
                                  [value]="
                                    part.Packages.Package.PackageUOM
                                      ? part.Packages.Package.PackageUOM
                                      : 'NA'
                                  "
                                  readonly
                                />
                              </div>
                              <div class="form-group">
                                <label for="input4"
                                  ><fa-icon
                                    [icon]="icon2"
                                    class="icon"
                                  ></fa-icon
                                  >Quantity of Eaches:</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="input4"
                                  [value]="
                                    part.Packages.Package.QuantityofEaches
                                      ? part.Packages.Package.QuantityofEaches
                                      : 'NA'
                                  "
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="mb-3">
                                <h5>
                                  <fa-icon [icon]="icon1" class="icon"></fa-icon
                                  >Dimensions:
                                </h5>
                                <textarea
                                class="form-control"
                                style="height: 100px"
                                [value]="
                                part.Packages.Package.Dimensions
                                  ? formatDimensions(
                                      part.Packages.Package.Dimensions
                                    )
                                  : 'NA'
                              "
                              readonly
                              ></textarea>
                                <!-- <input
                                  type="text"
                                  class="form-control"
                                  style="height: 100px; overflow-y: auto; white-space: pre-wrap;"
                                  [value]="
                                    part.Packages.Package.Dimensions
                                      ? formatDimensions(
                                          part.Packages.Package.Dimensions
                                        )
                                      : 'NA'
                                  "
                                  readonly
                                /> -->
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="mb-3">
                                <h5>
                                  <fa-icon [icon]="icon1" class="icon"></fa-icon
                                  >Weight:
                                </h5>
                                <textarea
                                class="form-control"
                                style="height: 100px"
                                [value]="
                                    part.Packages.Package.Weights
                                      ? formatWeights(
                                          part.Packages.Package.Weights
                                        )
                                      : 'NA'
                                  "
                              ></textarea>
                                <!-- <input
                                  type="text"
                                  class="form-control"
                                  style="height: 100px; overflow-y: auto; white-space: pre-wrap;"
                                  [value]="
                                    part.Packages.Package.Weights
                                      ? formatWeights(
                                          part.Packages.Package.Weights
                                        )
                                      : 'NA'
                                  "
                                  readonly
                                /> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="mt-3">
                          <div class="card-header">
                            <h5>
                              <fa-icon [icon]="icon1" class="icon2"></fa-icon
                              >Others:
                            </h5>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form-group">
                                <label for="input1">
                                  <fa-icon
                                    [icon]="icon2"
                                    class="icon2"
                                  ></fa-icon
                                  >Extended <br />
                                  Product <br />
                                  Information:</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="input1"
                                  placeholder="Input 1"
                                  [value]="
                                    part.ExtendedInformation
                                      .ExtendedProductInformation
                                  "
                                  readonly
                                />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                <label for="input1">
                                  <fa-icon
                                    [icon]="icon2"
                                    class="icon2"
                                  ></fa-icon
                                  >brandlabel:</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="input1"
                                  placeholder="Input 1"
                                  [value]="part.BrandLabel"
                                  readonly
                                />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                <label for="input1">
                                  <fa-icon
                                    [icon]="icon2"
                                    class="icon2"
                                  ></fa-icon
                                  >URL:</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="input1"
                                  placeholder="Input 1"
                                  [value]="
                                    part.DigitalAssets.DigitalFileInformation
                                      .URl
                                  "
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <!-- {{
                          part.PartInterchangeInfo.PartInterchange ? "part exists" : "no part exists"
                        }} -->
                        <div
                          class="mt-3"
                          *ngIf="
                            part.PartInterchangeInfo &&
                            part.PartInterchangeInfo.PartInterchange &&
                            part.PartInterchangeInfo.PartInterchange.length > 0
                          "
                        >
                          <div class="card-header">
                            <h5>
                              <fa-icon [icon]="icon1" class="icon2"></fa-icon
                              >Part Interchange Info:
                            </h5>
                          </div>
                          <div
                            class="card2"
                            *ngFor="
                              let value of part.PartInterchangeInfo
                                .PartInterchange
                            "
                          >
                            <div>
                              <div class="row">
                                <div class="col-6">
                                  <div class="form-group">
                                    <label for="input1"
                                      ><fa-icon
                                        [icon]="icon2"
                                        class="icon"
                                      ></fa-icon
                                      >Maintenance Type:</label
                                    >
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="input1"
                                      [value]="
                                        value.MaintenanceType
                                          ? value.MaintenanceType
                                          : 'NA'
                                      "
                                      readonly
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label for="input2"
                                      ><fa-icon
                                        [icon]="icon2"
                                        class="icon"
                                      ></fa-icon
                                      >Language Code:</label
                                    >
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="input2"
                                      [value]="
                                        value.LanguageCode
                                          ? value.LanguageCode
                                          : 'NA'
                                      "
                                      readonly
                                    />
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label for="input3"
                                      ><fa-icon
                                        [icon]="icon2"
                                        class="icon"
                                      ></fa-icon
                                      >Type Code:</label
                                    >
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="input3"
                                      [value]="
                                        value.TypeCode ? value.TypeCode : 'NA'
                                      "
                                      readonly
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label for="input4"
                                      ><fa-icon
                                        [icon]="icon2"
                                        class="icon"
                                      ></fa-icon
                                      >Brand AAIAID:</label
                                    >
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="input4"
                                      [value]="
                                        value.BrandAAIAID
                                          ? value.BrandAAIAID
                                          : 'NA'
                                      "
                                      readonly
                                    />
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label for="input3"
                                      ><fa-icon
                                        [icon]="icon2"
                                        class="icon"
                                      ></fa-icon
                                      >Part Number:</label
                                    >
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="input3"
                                      [value]="
                                        value.PartNumber
                                          ? value.PartNumber
                                          : 'NA'
                                      "
                                      readonly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <!-- </div> -->
            <div class="toggle-card" *ngIf="isCardOpen">
              <p>
                This is a card that toggles visibility when the arrow icon is
                clicked.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="noData" class="cardNoData no-data-card">
    <img
      src="https://5.imimg.com/data5/IN/NQ/MY-9965178/car-and-vehicle-selling-website-development-500x500.png"
      alt=""
      class="no-data-image"
    />
    <div class="no-data-text"><h1>No Data Found</h1></div>
  </div>
</div>

<!-- Cards container -->
<!-- <div *ngIf="isMoved" class="container cards-container mt-4">
  <div class="card mt-3">
  <mat-tab-group  mat-stretch-tabs="false" mat-align-tabs="start" color="primary" >
    <mat-tab label="Aces">
      <div class="card mt-3">
        <div class="card-body">
     
          <div class="table-container">
            <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Base Value</th>
                  <th>Sub</th>
                
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let datas of data">
                  <td>{{datas.id}}</td>
                  <td>{{datas.baseValue}}</td>
                  <td>{{datas.SubModel}}</td>
              
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Pies">
      <div class="card mt-3">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="input1"> <fa-icon [icon]="icon1" class="icon"></fa-icon>Label 1 :</label>
              <input type="text" class="form-control" id="input1" placeholder="Input 1">
            </div>
            <div class="form-group">
              <label for="input2"><fa-icon [icon]="icon1" class="icon"></fa-icon>Label 2 :</label>
              <input type="text" class="form-control" id="input2" placeholder="Input 2">
            </div>
            <div class="form-group">
              <label for="input3"><fa-icon [icon]="icon1" class="icon"></fa-icon>Label 3 :</label>
              <input type="text" class="form-control" id="input3" placeholder="Input 3">
            </div>
            <div class="form-group">
              <label for="input4"><fa-icon [icon]="icon1" class="icon"></fa-icon>Label 4 :</label>
              <input type="text" class="form-control" id="input4" placeholder="Input 4">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="input5"><fa-icon [icon]="icon1" class="icon"></fa-icon>Label 5 :</label>
              <input type="text" class="form-control" id="input5" placeholder="Input 5">
            </div>
            <div class="form-group">
              <label for="input6"><fa-icon [icon]="icon1" class="icon"></fa-icon>Label 6 :</label>
              <input type="text" class="form-control" id="input6" placeholder="Input 6">
            </div>
            <div class="form-group">
              <label for="input7"><fa-icon [icon]="icon1" class="icon"></fa-icon>Label 7 :</label>
              <input type="text" class="form-control" id="input7" placeholder="Input 7">
            </div>
            <div class="form-group">
              <label for="input8"><fa-icon [icon]="icon1" class="icon"></fa-icon>Label 8 :</label>
              <input type="text" class="form-control" id="input8" placeholder="Input 8">
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
</div> -->
