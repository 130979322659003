import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

interface Part {
  [key: string]: string;
}

interface PartData {
  partNumber: string;
  uniqueKeyArray: string[];
  acesPartArray: Part[];
  acesPartObject: {
    Qty: string;
    [key: string]: string;
  };
}

interface NewPartData {
  MaintenanceType: string;
  ItemLevelGTIN: string;
  PartNumber: string;
  BrandAAIAID: string;
  QuantityPerApplication: string;
  PartTerminologyID: string;
  [key: string]: any;
}

interface Pricing {
  MaintenanceType: string;
  PriceType: string;
  PriceSheetNumber: string;
  EffectiveDate: string;
  Price: string;
}

@Injectable({
  providedIn: 'root'
})
export class PartsService {

  // private apiUrl = 'https://adminapidev.autochecker.com/adminapi/product/searchAces';

  // data: PartData[] = [{
  //   "partNumber": "17-1006",
  //   "uniqueKeyArray": [
  //     "BaseVehicle",
  //     "Note",
  //     "PartType",
  //     "Position"
  //   ],
  //   "acesPartArray": [
  //     {
  //       "BaseVehicle": "2005, Dodge, Sprinter 2500",
  //       "SubModel": "NA",
  //       "BodyType": "NA",
  //       "DriveType": "NA",
  //       "EngineBase": "NA",
  //       "EngineDesignation": "NA",
  //       "FuelDeliverySubType": "NA",
  //       "Aspiration": "NA",
  //       "FuelType": "NA",
  //       "TransmissionControlType": "NA",
  //       "TransmissionNumSpeeds": "NA",
  //       "Note": "ATE Calipers; 272mm Rear Rotor; Pin Type Wear Sensor, Requires Wear Sensor - Sold Separately",
  //       "PartType": "Disc Brake Pad Set",
  //       "Position": "Rear",
  //       "cylinderheadtype": "NA"
  //     },
  //     {
  //       "BaseVehicle": "2005, Dodge, Sprinter 3500",
  //       "SubModel": "NA",
  //       "BodyType": "NA",
  //       "DriveType": "NA",
  //       "EngineBase": "NA",
  //       "EngineDesignation": "NA",
  //       "FuelDeliverySubType": "NA",
  //       "Aspiration": "NA",
  //       "FuelType": "NA",
  //       "TransmissionControlType": "NA",
  //       "TransmissionNumSpeeds": "NA",
  //       "Note": "ATE Calipers; 272mm Rear Rotor; Pin Type Wear Sensor, Requires Wear Sensor - Sold Separately",
  //       "PartType": "Disc Brake Pad Set",
  //       "Position": "Rear",
  //       "cylinderheadtype": "NA"
  //     },
  //     {
  //       "BaseVehicle": "2006, Dodge, Sprinter 3500",
  //       "SubModel": "NA",
  //       "BodyType": "NA",
  //       "DriveType": "NA",
  //       "EngineBase": "NA",
  //       "EngineDesignation": "NA",
  //       "FuelDeliverySubType": "NA",
  //       "Aspiration": "NA",
  //       "FuelType": "NA",
  //       "TransmissionControlType": "NA",
  //       "TransmissionNumSpeeds": "NA",
  //       "Note": "ATE Calipers; 272mm Rear Rotor; Pin Type Wear Sensor, Requires Wear Sensor - Sold Separately",
  //       "PartType": "Disc Brake Pad Set",
  //       "Position": "Rear",
  //       "cylinderheadtype": "NA"
  //     },
  //     {
  //       "BaseVehicle": "2006, Dodge, Sprinter 2500",
  //       "SubModel": "NA",
  //       "BodyType": "NA",
  //       "DriveType": "NA",
  //       "EngineBase": "NA",
  //       "EngineDesignation": "NA",
  //       "FuelDeliverySubType": "NA",
  //       "Aspiration": "NA",
  //       "FuelType": "NA",
  //       "TransmissionControlType": "NA",
  //       "TransmissionNumSpeeds": "NA",
  //       "Note": "ATE Calipers; 272mm Rear Rotor; Pin Type Wear Sensor, Requires Wear Sensor - Sold Separately",
  //       "PartType": "Disc Brake Pad Set",
  //       "Position": "Rear",
  //       "cylinderheadtype": "NA"
  //     },
  //     {
  //       "BaseVehicle": "2003, Dodge, Sprinter 2500",
  //       "SubModel": "NA",
  //       "BodyType": "NA",
  //       "DriveType": "NA",
  //       "EngineBase": "NA",
  //       "EngineDesignation": "NA",
  //       "FuelDeliverySubType": "NA",
  //       "Aspiration": "NA",
  //       "FuelType": "NA",
  //       "TransmissionControlType": "NA",
  //       "TransmissionNumSpeeds": "NA",
  //       "Note": "ATE Calipers; 272mm Rear Rotor; Pin Type Wear Sensor, Requires Wear Sensor - Sold Separately",
  //       "PartType": "Disc Brake Pad Set",
  //       "Position": "Rear",
  //       "cylinderheadtype": "NA"
  //     }
  //   ],
  //   "acesPartObject": {
  //     "Qty": "1",
  //     "MfrLabel": "Power Stop - Rear Z17 Low-Dust Ceramic Brake Pads with Hardware"
  //   }
  // }
  // ]

  data: PartData[] = [];


  // newPartData = [
  //   {
  //     "MaintenanceType": "A",
  //     "ItemLevelGTIN": "00085337330198",
  //     "PartNumber": "2590",
  //     "BrandAAIAID": "BBDL",
  //     "ACESApplications": "Y",
  //     "QuantityPerApplication": "1",
  //     "MinimumOrderQuantity": "1",
  //     "PartTerminologyID": "5872",
  //     "Descriptions": {
  //       "Description": [
  //         "AP Emissions",
  //         "AP Challenge Muffler",
  //         "Our best Direct Fit OE replacement muffler; Constructed from 100% Aluminized Steel; Original Equipment Tuning matches OE tone; Backed by a Limited Lifetime Warranty",
  //         "Muffler - Challenge",
  //         "Muffler - Challenge; Oval Body - H4.5\" X W11\" X L16\"; 30\" OAL; 2\" OD Config. O/OO"
  //       ]
  //     },
  //     "Prices": {
  //       "Pricing": [
  //         {
  //           "MaintenanceType": "A",
  //           "PriceType": "JBR",
  //           "PriceSheetNumber": "01",
  //           "EffectiveDate": "2022-12-05",
  //           "Price": "144.5200"
  //         },
  //         {
  //           "MaintenanceType": "A",
  //           "PriceType": "LST",
  //           "PriceSheetNumber": "01",
  //           "EffectiveDate": "2022-12-05",
  //           "Price": "289.0400"
  //         }
  //       ]
  //     },
  //     "ExtendedInformation": {
  //       "ExtendedProductInformation": [
  //         "US",
  //         "8708922910",
  //         "C",
  //         "Next 15% of Product Group Sales Value",
  //         "2",
  //         "Available to Order"
  //       ]
  //     },
  //     "ProductAttributes": {
  //       "ProductAttribute": [
  //         "4.5",
  //         "16",
  //         "Aluminized Steel",
  //         "11",
  //         "Regular",
  //         "No",
  //         "Right Center",
  //         "2",
  //         "1",
  //         "Flange",
  //         "O/OO",
  //         "Baffled",
  //         "Left Center",
  //         "2",
  //         "1",
  //         "Pipe",
  //         "30",
  //         "Challenge",
  //         "Oval",
  //         "OE comparable",
  //         "No"
  //       ]
  //     },
  //     "Packages": {
  //       "Package": {
  //         "MaintenanceType": "A",
  //         "PackageUOM": "EA",
  //         "QuantityofEaches": "1",
  //         "Dimensions": {
  //           "UOM": "IN",
  //           "Height": "5.3800",
  //           "Width": "14.1200",
  //           "Length": "31.3800"
  //         },
  //         "Weights": {
  //           "UOM": "PG",
  //           "Weight": "14.8600"
  //         }
  //       }
  //     },
  //     "DigitalAssets": {
  //       "DigitalFileInformation": [
  //         {
  //           "MaintenanceType": "A",
  //           "LanguageCode": "EN",
  //           "FileName": "APX-2590_P04_TOP.jpg",
  //           "AssetType": "P04",
  //           "FileType": "JPG",
  //           "Representation": "A",
  //           "Resolution": "300",
  //           "OrientationView": "TOP",
  //           "AssetDimensions": {
  //             "UOM": "PX",
  //             "AssetHeight": "1500",
  //             "AssetWidth": "1500"
  //           }
  //         },
  //         {
  //           "MaintenanceType": "A",
  //           "LanguageCode": "EN",
  //           "FileName": "APX-2590_ZZ2_BAC.jpg",
  //           "AssetType": "ZZ2",
  //           "FileType": "JPG",
  //           "Representation": "A",
  //           "Resolution": "300",
  //           "OrientationView": "BAC",
  //           "AssetDimensions": {
  //             "UOM": "PX",
  //             "AssetHeight": "1500",
  //             "AssetWidth": "1500"
  //           }
  //         },
  //         {
  //           "MaintenanceType": "A",
  //           "LanguageCode": "EN",
  //           "FileName": "APX-2590_ZZ4_FRO.jpg",
  //           "AssetType": "ZZ4",
  //           "FileType": "JPG",
  //           "Representation": "A",
  //           "Resolution": "300",
  //           "OrientationView": "FRO",
  //           "AssetDimensions": {
  //             "UOM": "PX",
  //             "AssetHeight": "1500",
  //             "AssetWidth": "1500"
  //           }
  //         }
  //       ]
  //     }
  //   },
  //   {
  //     "MaintenanceType": "A",
  //     "HazardousMaterialCode": "N",
  //     "ItemLevelGTIN": "00085337330198",
  //     "PartNumber": "2590",
  //     "BrandAAIAID": "BBDL",
  //     "BrandLabel": "AP Exhaust",
  //     "ACESApplications": "Y",
  //     "QuantityPerApplication": "1",
  //     "AvailableDate": "2022-07-01",
  //     "MinimumOrderQuantity": "1",
  //     "ManufacturerProductCodes": {
  //       "Group": "PL51"
  //     },
  //     "PartTerminologyID": "5872",
  //     "Descriptions": {
  //       "Description": [
  //         "Our best Direct Fit OE replacement muffler; Constructed from 100% Aluminized Steel; Original Equipment Tuning matches OE tone; Backed by a Limited Lifetime Warranty",
  //         "Muffler - Challenge",
  //         "AP Challenge Muffler",
  //         "AP Emissions",
  //         "2017-2012 Toyota Camry"
  //       ]
  //     },
  //     "Prices": {
  //       "Pricing": [
  //         {
  //           "MaintenanceType": "A",
  //           "PriceType": "LST",
  //           "PriceSheetNumber": "2024Q1US",
  //           "Price": "289.0400"
  //         },
  //         {
  //           "MaintenanceType": "A",
  //           "PriceType": "JBR",
  //           "PriceSheetNumber": "2024Q1US",
  //           "Price": "144.5200"
  //         }
  //       ]
  //     },
  //     "ExtendedInformation": {
  //       "ExtendedProductInformation": [
  //         "2",
  //         "Available to Order",
  //         "US",
  //         "8708922910",
  //         "C",
  //         "Next 15% of Product Group Sales Value"
  //       ]
  //     },
  //     "ProductAttributes": {
  //       "ProductAttribute": [
  //         "4.500",
  //         "16.000",
  //         "Aluminized Steel",
  //         "11.000",
  //         "Yes",
  //         "Regular",
  //         "No",
  //         "Right Center",
  //         "2.000",
  //         "1",
  //         "Flange",
  //         "Left Center",
  //         "Baffled",
  //         "2.000",
  //         "1",
  //         "Pipe",
  //         "30.000",
  //         "Challenge",
  //         "OE comparable",
  //         "No"
  //       ]
  //     },
  //     "Packages": {
  //       "Package": {
  //         "MaintenanceType": "A",
  //         "PackageLevelGTIN": "00085337330198",
  //         "PackageUOM": "EA",
  //         "QuantityofEaches": "1",
  //         "Dimensions": {
  //           "UOM": "IN",
  //           "MerchandisingHeight": "5.380",
  //           "MerchandisingWidth": "14.120",
  //           "MerchandisingLength": "31.380"
  //         },
  //         "Weights": {
  //           "UOM": "LB",
  //           "Weight": "14.860"
  //         }
  //       }
  //     },
  //     "DigitalAssets": {
  //       "DigitalFileInformation": [
  //         {
  //           "MaintenanceType": "A",
  //           "FileName": "APX-2590_P04_TOP.jpg",
  //           "AssetType": "P04",
  //           "FileType": "JPG",
  //           "Representation": "A",
  //           "ColorMode": "GRA",
  //           "Background": "WHI",
  //           "OrientationView": "TOP"
  //         },
  //         {
  //           "MaintenanceType": "A",
  //           "FileName": "APX-2590_ZZ2_BAC.jpg",
  //           "AssetType": "ZZ2",
  //           "FileType": "JPG",
  //           "Representation": "A",
  //           "ColorMode": "GRA",
  //           "Background": "WHI",
  //           "OrientationView": "BAC"
  //         },
  //         {
  //           "MaintenanceType": "A",
  //           "FileName": "APX-2590_ZZ4_FRO.jpg",
  //           "AssetType": "ZZ4",
  //           "FileType": "JPG",
  //           "Representation": "A",
  //           "ColorMode": "GRA",
  //           "Background": "WHI",
  //           "OrientationView": "FRO"
  //         }
  //       ]
  //     }
  //   },
  //   {
  //     "MaintenanceType": "N",
  //     "HazardousMaterialCode": "N",
  //     "ItemLevelGTIN": "00015964230707",
  //     "PartNumber": "2590",
  //     "BrandAAIAID": "FLGL",
  //     "ACESApplications": "Y",
  //     "ItemQuantitySize": "1",
  //     "ContainerType": "BG",
  //     "QuantityPerApplication": "1",
  //     "MinimumOrderQuantity": "1",
  //     "PartTerminologyID": "7410",
  //     "Descriptions": {
  //       "Description": [
  //         "PS Repair Kit",
  //         "Seal Kit",
  //         "STG. GEAR CONTROL VALVE SEAL KIT",
  //         "1993-57 Chry. Products",
  //         "Premium quality parts for lasting repairs. Engineered to OE design and specifications."
  //       ]
  //     },
  //     "ExtendedInformation": {
  //       "ExtendedProductInformation": [
  //         "2",
  //         "US",
  //         "8708947550",
  //         "N"
  //       ]
  //     },
  //     "Packages": {
  //       "Package": {
  //         "MaintenanceType": "N",
  //         "PackageLevelGTIN": "00015964230707",
  //         "PackageBarCodeCharacters": "015964230707",
  //         "PackageUOM": "EA",
  //         "QuantityofEaches": "1",
  //         "InnerQuantity": "1",
  //         "Dimensions": {
  //           "UOM": "IN",
  //           "Height": "1.75",
  //           "Width": "5.25",
  //           "Length": "5.5"
  //         },
  //         "Weights": {
  //           "UOM": "PG",
  //           "Weight": "0.1"
  //         }
  //       }
  //     },
  //     "PartInterchangeInfo": {
  //       "PartInterchange": [
  //         {
  //           "MaintenanceType": "N",
  //           "LanguageCode": "EN",
  //           "TypeCode": "S",
  //           "BrandAAIAID": "BBFH",
  //           "PartNumber": "5-2590"
  //         },
  //         {
  //           "MaintenanceType": "N",
  //           "LanguageCode": "EN",
  //           "TypeCode": "S",
  //           "BrandAAIAID": "GBMS",
  //           "PartNumber": "8518"
  //         }
  //       ]
  //     },
  //     "DigitalAssets": {
  //       "DigitalFileInformation": [
  //         {
  //           "MaintenanceType": "N",
  //           "FileName": "2590.jpg",
  //           "AssetType": "P04",
  //           "FileType": "JPG",
  //           "Representation": "A",
  //           "OrientationView": "FRO"
  //         },
  //         {
  //           "MaintenanceType": "N",
  //           "FileName": "prop_25.jpg",
  //           "AssetType": "ZZ1",
  //           "FileType": "JPG",
  //           "URI": "prop_25.jpg"
  //         }
  //       ]
  //     }
  //   }
  // ]

  newPartData: any = [];

  constructor(private http: HttpClient) { }

  getAces(data: any): Observable<any> {
    return this.http.post<any>('https://adminapidev.autochecker.com/adminapi/product/searchAces', data);
  }

  getPies(data: any): Observable<any> {
    return this.http.post<any>('https://adminapidev.autochecker.com/adminapi/product/searchPies', data);
  }

  getParts() {
    const originalHeaders = this.data[0].uniqueKeyArray; // Keep the original headers
    const headers = originalHeaders.map(header => this.addSpaces(header));
    const parts = this.data[0].acesPartArray.map(part => {
      let filteredPart: Part = {};
      originalHeaders.forEach((header, index) => {
        const transformedHeader = headers[index]; // Use the transformed header
        filteredPart[transformedHeader] = part[header];
      });
      return filteredPart;
    });
    return {
      headers,
      parts
    };
  }

  addSpaces(header: string): string {
    return header.replace(/([A-Z])/g, ' $1').trim();
  }


  getAcesPartObject(): { [key: string]: string } {
    const originalAcesPartObject = this.data[0].acesPartObject;
    const transformedAcesPartObject: { [key: string]: string } = {};

    for (const key in originalAcesPartObject) {
      if (Object.prototype.hasOwnProperty.call(originalAcesPartObject, key)) {
        const transformedKey = this.addSpaces(key);
        transformedAcesPartObject[transformedKey] = originalAcesPartObject[key];
      }
    }

    return transformedAcesPartObject;
  }

  getPartNumber(): string {
    return this.data[0].partNumber;
  }

  // getNewPartData(): Partial<NewPartData>[] {
  //   return this.newPartData.map(part => {
  //     return {
  //       MaintenanceType: part.MaintenanceType,
  //       ItemLevelGTIN: part.ItemLevelGTIN,
  //       PartNumber: part.PartNumber,
  //       BrandAAIAID: part.BrandAAIAID,
  //       QuantityPerApplication: part.QuantityPerApplication,
  //       PartTerminologyID: part.PartTerminologyID
  //     };
  //   });
  // }

  // getPricesData(): Pricing[] {
  //   const prices: Pricing[] = [];
  //   this.newPartData.forEach((part: any, i) => {
  //     if (part['Prices'] && part['Prices'].Pricing) {
  //       part['Prices'].Pricing.forEach((price: any) => {
  //         // Add the additional property here
  //         price.indexValue = i; // Modify this line as needed
  //         prices.push(price);
  //       });
  //       //prices.push(...part['Prices'].Pricing);
  //     }
  //   });
  //   return prices;
  // }

  // getProductAttributes(): string[] {
  //   return this.newPartData[0]['ProductAttributes']?.ProductAttribute || [];
  // }

  // getDescriptions(): string[] {
  //   return this.newPartData[0]['Descriptions']?.Description || [];
  // }

  // getPackages(): any {
  //   return this.newPartData[0]['Packages']?.Package || {};
  // }
}

